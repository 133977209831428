<template>

  <div>
    <section class="transport-section transport-style-four pt-4 mt-4">
      <div class="container">
        <div class="section-title text-center">

          <h2>Comment ça marche ?</h2>
        </div>

        <div class="row">
          <div
            class="col-lg-2 col-sm-6 wow fadeInUp"
            data-wow-duration="1s"
          >
            <div class="transport-card text-center">
              <div class="transport-text">

                <i class="icofont-login" /><br><br>

                <h6>Inscription  sur eBDFRET</h6>
                <!--  <p>Créer un Compte eBDFRET  gratuitement et facilement  </p><br><br><br><br>-->
                <a href="#" />
              </div>
            </div>
          </div>
          <div
            class="col-lg-2 col-sm-6 wow fadeInUp"
            data-wow-duration="1s"
          >
            <div class="transport-card text-center">
              <div class="transport-text">
                <i class="icofont-list" /><br><br>
                <h6>Réceptiion des offres  </h6>
                <!--<p>Publier vos différentes offres de transport et laisser eBDFRET  vous mettre  en contacte avec les meilleurs transporteurs </p>-->
                <a href="#" />
              </div>
            </div>
          </div>

          <div
            class="col-lg-2 col-sm-6 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".3s"
          >
            <div class="transport-card text-center">
              <div class="transport-text">
                <i class="icofont-contact-add" /><br><br>
                <h6>Propositoon de devis</h6>
                <!--   <p>Recevez rapidement des proposotions devis  et validez le devis qui vous convient</p><br>-->
                <a href="#" />
              </div>
            </div>
          </div>

          <div
            class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".6s"
          >
            <div class="transport-card text-center">
              <div class="transport-text">
                <i class="icofont-car-alt-2" /><br><br>
                <h6>La livraison</h6>
                <!--   <p>A cargo aircraft also known as freight aircraft, freighter, airlifter or cargo jet is a fixed-wing aircraft that is designed.</p>-->
                <a href="#" />
              </div>
            </div>
          </div>

          <div
            class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".6s"
          >
            <div class="transport-card text-center">
              <div class="transport-text">
                <i class="icofont-checked" /><br><br>
                <h6> Envoyez une preuve de livraison</h6>
                <!--   <p>A cargo aircraft also known as freight aircraft, freighter, airlifter or cargo jet is a fixed-wing aircraft that is designed.</p>-->
                <a href="#" />
              </div>
            </div>
          </div>

          <div
            class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".6s"
          >
            <div class="transport-card text-center">
              <div class="transport-text">
                <i class="icofont-attachment" /><br><br>
                <h6>Recevoir une note</h6>
                <!--   <p>A cargo aircraft also known as freight aircraft, freighter, airlifter or cargo jet is a fixed-wing aircraft that is designed.</p>-->
                <a href="#" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>
<script>
export default {

}
</script>
<style >

</style>
